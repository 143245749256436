<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <!-- <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='sticky'>
        <a-select v-model="form.sticky" placeholder="活动类型" style="width: 250px">
          <a-select-option value="">
            活动类型
          </a-select-option>
          <a-select-option value="0">
            庆功宴
          </a-select-option>
          <a-select-option value="1">
            新人启航
          </a-select-option>
          <a-select-option value="2">
            新增
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='masterName'>
        <a-input v-model='form.masterName' style="width: 350px" placeholder="负责人" />
      </a-form-model-item>
      <a-form-model-item prop='status'>
        <a-select v-model="form.status" placeholder="状态" style="width: 250px">
          <a-select-option value="">
            状态
          </a-select-option>
          <a-select-option :value="0">
            筹备中
          </a-select-option>
          <a-select-option :value="1">
            进行中
          </a-select-option>
          <a-select-option :value="2">
            已结束
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model> -->
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>
      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='atitle' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              {{ text }}
            </a>
          </template>
        </span>
        <span slot='replyTime' slot-scope='text'>
          <template>
            <a-badge dot v-if='text' :status='"success"' :text='"已回复"'></a-badge>
            <a-badge dot v-else :status='"error"' :text='"待回复"'></a-badge>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='handleEdit(record)'>查看</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryProposal, deleteProposal } from '@/api/proposal'

const columns = [
  {
    width: 200,
    title: '总结主题',
    dataIndex: 'title',
    scopedSlots: { customRender: 'atitle' }
  },
  {
    width: 150,
    title: '提交人',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'nickname' }
  },
  {
    width: 200,
    title: '提交时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '状态',
    dataIndex: 'replyTime',
    scopedSlots: { customRender: 'replyTime' }
  },
  {
    width: 100,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ProposalList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      form: {},
      loading: false,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        return queryProposal(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            return datum
          })
      },
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {
          mobile: [
            { required: true, message: '请填写注册手机号', trigger: 'change' },
            { pattern: /^1[3456789]\d{9}$/, message: '请填写注册手机号' }
          ],   
          star: [
            { required: true, message: '请填星数', trigger: 'change' },
            { type: 'integer', message: '星数填写整数', trigger: 'change' }
          ],  
          emptyStar: [
            { required: true, message: '请填星数', trigger: 'change' },
            { type: 'integer', message: '星数填写整数', trigger: 'change' }
          ],
        },
      },
    }
  },
  created() {},
  methods: {
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleDelete(record) {
      deleteProposal({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleEdit(record) {
      this.$router.push({ path: '/activity/proposal/edit', query: { id: record.id } })
    },
  }
}
</script>
<style lang="less" scoped>
</style>
